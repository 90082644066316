// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-page-careers-about-tsx": () => import("./../../../src/components/page/careers/about.tsx" /* webpackChunkName: "component---src-components-page-careers-about-tsx" */),
  "component---src-components-page-cookies-cookie-policy-tsx": () => import("./../../../src/components/page/cookies/cookie-policy.tsx" /* webpackChunkName: "component---src-components-page-cookies-cookie-policy-tsx" */),
  "component---src-components-page-home-home-page-tsx": () => import("./../../../src/components/page/home/homePage.tsx" /* webpackChunkName: "component---src-components-page-home-home-page-tsx" */),
  "component---src-components-page-legal-legal-page-tsx": () => import("./../../../src/components/page/legal/legalPage.tsx" /* webpackChunkName: "component---src-components-page-legal-legal-page-tsx" */),
  "component---src-components-page-product-download-product-download-tsx": () => import("./../../../src/components/page/product/download/productDownload.tsx" /* webpackChunkName: "component---src-components-page-product-download-product-download-tsx" */),
  "component---src-components-page-product-product-page-tsx": () => import("./../../../src/components/page/product/productPage.tsx" /* webpackChunkName: "component---src-components-page-product-product-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

